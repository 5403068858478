<template>
  <div class="container-fluid main-color">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="page-title-right">
            <router-link
              :to="{
                name: 'trainer-ojt-assessment',
              }"
            >
              <button
                type="button"
                class="btn btn-sm btn-blue waves-effect waves-light mb-2"
              >
                <i class="mdi mdi-arrow-left"></i>
                Back
              </button>
            </router-link>
          </div>
          <h4 class="page-title">Superior assessment details</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <place-holder v-if="loading"></place-holder>
        <div class="card">
          <div v-if="!loading" class="card-body">
            <form class="parsley-examples">
              <div class="row">
                <div class="col-6">
                  <div class="row col-md-11">
                    <div class="row mb-3">
                      <label for="date" class="col-md-4 form-label">
                        Employee Name
                        <!-- <span class="text-danger">*</span> -->
                      </label>
                      <div class="col-md-8">
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          v-model="ojtAssessment.employee_name"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row col-md-11">
                    <div class="row mb-3">
                      <label for="date" class="col-md-4 form-label">
                        Employee ID
                        <!-- <span class="text-danger">*</span> -->
                      </label>
                      <div class="col-md-8">
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          v-model="ojtAssessment.employee_id"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row col-md-11">
                    <div class="row mb-3">
                      <label for="date" class="col-md-4 form-label">
                        Program Name
                        <!-- <span class="text-danger">*</span> -->
                      </label>
                      <div class="col-md-8">
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          v-model="ojtAssessment.program_name"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="row col-md-11">
                    <div class="row mb-3">
                      <label for="date" class="col-md-4 form-label">
                        Batch
                        <!-- <span class="text-danger">*</span> -->
                      </label>
                      <div class="col-md-8">
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          v-model="ojtAssessment.batch"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row col-md-11">
                    <div class="row mb-3">
                      <label for="date" class="col-md-4 form-label">
                        Joined Date
                        <!-- <span class="text-danger">*</span> -->
                      </label>
                      <div class="col-md-8">
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          v-model="ojtAssessment.join_date"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row col-md-11">
                    <div class="row mb-3">
                      <label for="date" class="col-md-4 form-label">
                        Level
                        <!-- <span class="text-danger">*</span> -->
                      </label>
                      <div class="col-md-8">
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          v-model="ojtAssessment.level"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="progress mt-3"
                style="height: 0.5px; background-color: #ccc !important"
              >
                <div class="progress-bar" role="progressbar"></div>
              </div>

              <div class="row g-3 mt-3">
                <div class="col-sm-4">
                  <h4 class="mb-3">Criteria</h4>
                </div>
                <div class="col-sm-4">
                  <h4 class="mb-3">Scores:*</h4>
                </div>
                <div class="col-sm-4">
                  <h4 class="mb-3">Remark:</h4>
                </div>
              </div>

              <div
                class="row g-3"
                v-for="(
                  item, index
                ) in ojtAssessment.trainer_assessment_ojt_assessment"
                :key="index"
              >
                <div class="col-sm-4">
                  <p class="mb-3 pt-2 pl-2">{{ item.name }}</p>
                </div>
                <div class="col-sm-4">
                  <div class="mt-2">
                    <div class="">
                      <input
                        type="text"
                        class="form-control"
                        disabled
                        v-model="item.score"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-4" v-if="index == 0">
                  <div class="mt-2">
                    <div class="">
                      <input
                        type="text"
                        class="form-control"
                        disabled
                        v-model="ojtAssessment.remark"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="row g-3">
                <div class="col-sm-4">
                  <p class="mb-3 pt-2 pl-2">Total Score</p>
                </div>
                <div class="col-sm-4">
                  <div class="mt-2">
                    <input
                      type="text"
                      class="form-control"
                      v-model="totalScore"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import validationErrors from "../../../mixins/validationErrors";

export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },
  mixins: [validationErrors],
  data() {
    return {
      ojtAssessment: {},
      totalScore: 0,
      loading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  methods: {
    async getOjtAssessmentDetail() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(
          `${this.baseUrl}v2/ojt-assessment-get-assessment-details/${this.$route.params.id}`
        )
        .then((response) => {
          //
          this.ojtAssessment = response.data.data.ojt_assessment_details;
          this.totalScore =
            this.ojtAssessment.trainer_assessment_ojt_assessment.reduce(
              (accumulator, currentValue) => {
                return accumulator + currentValue.score;
              },
              0
            );
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.toast.error("Something went wrong");
          console.log(error);
          this.loading = false;
          this.$Progress.finish();
        });
    },
  },
  created() {
    this.getOjtAssessmentDetail();
  },
};
</script>

<style>
.custom-selectCSS {
  --vs-dropdown-min-width: 160px;
  --vs-dropdown-max-height: 200px;
}
.custom-v-select .vs__dropdown-toggle .vs__open-indicator::before {
  display: none;
}
</style>
